import  firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/storage";

  
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var app = firebase.initializeApp({
    apiKey: "AIzaSyDHD1uc5eXjOgcluXw9Q_xxSnuWzAl_QDo",
    authDomain: "alomawicorp-9392e.firebaseapp.com",
    projectId: "alomawicorp-9392e",
    storageBucket: "alomawicorp-9392e.appspot.com",
    messagingSenderId: "43821291134",
    appId: "1:43821291134:web:883ce80c9c9a35ab0f2a2f",
    measurementId: "G-QT0CY84YC2",
  });

  
   
 
  // Initialize Firebase
  const dbStorage = firebase.storage(); 
const auth = firebase.auth();
const db = firebase.firestore();
  // Create a reference to the SF doc.
  //  const getData = ()=>{
  //   var sfDocRef = firebase.collection("visitor");

  //   // Uncomment to initialize the doc.
  //   // sfDocRef.set({ population: 0 });

  //   return firebase
  //     .runTransaction((transaction) => {
  //       // This code may get re-run multiple times if there are conflicts.
  //       return transaction.get(sfDocRef).then((sfDoc) => {
  //         // if (!sfDoc.exists) {
  //         //     throw "Document does not exist!";
  //         // }

  //         // Add one person to the city population.
  //         // Note: this could be done without a transaction
  //         //       by updating the population using FieldValue.increment()
  //         var newPopulation = sfDoc.data().population + 1;
  //         transaction.update(sfDocRef, { population: newPopulation });
  //       });
  //     })
  //     .then(() => {
  //       console.log("Transaction successfully committed!");
  //     })
  //     .catch((error) => {
  //       console.log("Transaction failed: ", error);
  //     });

  // }

  // const projStorage = firebase.storage();

 export { db, auth, dbStorage };
export default app
 