import React from "react";

import "./aboutUs.css";

// react npm packege module
import { motion } from "framer-motion";
import useScrollPosition from "@react-hook/window-scroll";
// import care from "../../assets/SliderImage/care.png";
import ourMission from "../../assets/aboutUs/ourMission.png";
import ourVision from "../../assets/aboutUs/ourVision.png";
import omawi from "../../assets/aboutUs/omawi.png";

const AboutUs = () => {
  const scrollY = useScrollPosition(60 /*frames per second*/);

  const variants = {
    hidden: {
      scale: 0.5,
      y: 10,
    },
  };

  const variantsVis = {
    visibale: {
      scale: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="aboutUsContainer" id="aboutUs">
      <h1>ABOUT US</h1>
      <div className="WhoWERAbout">
        <div className="logoOmawi">
          <img src={omawi} alt="" />
        </div>
        <div className="shadowOmawi">
          <div className="headOmawi">
            WHO <br /> WE ARE
          </div>

          <motion.div
            className="textOmawi"
            variants={scrollY > 1100 ? variantsVis : variants}
            initial="hidden"
            animate="visibale"
          >
            In Al-Omawi drug store we strive to be the best, dealing with
            pharmacies all over the country and operating since 2007 located in
            Jordan.
          </motion.div>
          <div className="visiable">
            In Al-Omawi drug store we strive to be the best, dealing with
            pharmacies all over the country and operating since 2007 located in
            Jordan.
          </div>
        </div>
      </div>

      <div className="test">
        <div className="aboutUsImage">
          <img src={ourVision} alt="" />
        </div>
        <div className="imagShadow">
          <div className="textHeadAbout">OUR MISSION</div>
          <motion.div
            className="textDefAbout"
            variants={scrollY > 1500 ? variantsVis : variants}
            initial="hidden"
            animate="visibale"
          >
            To provide innovative pharmaceutical care products that shifts the
            tides of health care products quality in the Jordanian market,
            having the patient’s health as the primary goal in the process.
          </motion.div>

          <div className="textDefAboutVisibale">
            To provide innovative pharmaceutical care products that shifts the
            tides of health care products quality in the Jordanian market,
            having the patient’s health as the primary goal in the process.
          </div>
        </div>
      </div>

      <div className="aboutUsImage">
        {/* <img src={ourMission} alt="" /> */}
        <img src={ourMission} alt="" />
      </div>
      <div className="imagShadow2">
        <div className="textHeadAbout">OUR VISION</div>
        <motion.div
          className="textDefAbout"
          variants={scrollY > 2100 ? variantsVis : variants}
          initial="hidden"
          animate="visibale"
        >
          Continuing to achieve excellence in our journey until we become the
          top leading drug store in Jordan and expanding to be the main
          distributer of the Middle East while keeping the patient’s best
          interest the greatest priority at hand.
        </motion.div>

        <div className="textDefAboutVisibale">
          Continuing to achieve excellence in our journey until we become the
          top leading drug store in Jordan and expanding to be the main
          distributer of the Middle East while keeping the patient’s best
          interest the greatest priority at hand.
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
