import React, { useState } from "react";
import useScrollPosition from "@react-hook/window-scroll";
import { motion } from "framer-motion";
import "./headerbar.css";

// import react modules

import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimesCircle } from "react-icons/fa";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// import react components
import logo from "../../assets/logo/logo.png";
import logoBlue from "../../assets/logo/logoBlue.png";

function HeaderBar() {
  // for animation logo
  const scrollY = useScrollPosition(60 /*frames per second*/);

  const [styles, setStyles] = useState({
    home: "actveMenu",
    whiteHome: "whiteActiveMenu",
    about: "",
    whiteAbout: "",
    product: "",
    whiteProduct: "",
    contact: "",
    whiteContact: "",
    career: "",
    whiteCareer: "",
    pharma: "",
    whitePharma: "",
  });

  const variants = {
    visible: {
      width: "160px",
      height: "55px",
    },
  };
  const variantsS = {
    hidden: {
      width: "300px",
      height: "100px",
    },
  };

  const Hide = () => {
    setSmall(!small);
    setClick(true);
  };

  const HideMenuOnClick = () => {
    setSmall(!small);
    setClick(false);
  };
  const [big] = useState(true);
  const [small, setSmall] = useState(false);
  const [click, setClick] = useState(true);
  return (
    <div className="headerContainer">
      <div
        className="settingsValue"
        style={scrollY > 100 ? { display: "none" } : null}
      >
        <h3> Setting valuable products your way </h3>
      </div>
      <div
        className={scrollY > 100 ? "headerBar headerVisColor" : "headerBar"}
        style={scrollY > 100 ? { top: "0" } : null}
      >
        <div className="header ">
          <div className="mobile">
            <button className="closebigIcon" onClick={() => Hide()}>
              {small ? (
                <FaTimesCircle color="#084cb3" size="2rem" />
              ) : (
                <GiHamburgerMenu color="#084cb3" size="2rem" />
              )}
            </button>
          </div>

          <Link to="/">
            <motion.div className="logoBigImage">
              <motion.img
                className="logo"
                variants={scrollY > 100 ? variants : variantsS}
                initial="hidden"
                animate="visible"
                src={scrollY > 100 ? logoBlue : logo}
                alt="logo"
                onClick={() =>
                  setStyles({
                    home: "actveMenu",
                    whiteHome: "whiteActiveMenu",
                    about: "",
                    whiteAbout: "",
                    product: "",
                    whiteProduct: "",
                    contact: "",
                    whiteContact: "",
                    career: "",
                    whiteCareer: "",
                    pharma: "",
                    whitePharma: "",
                  })
                }
              />
            </motion.div>
            <div className="blueImageSmall">
              <img
                src={logoBlue}
                alt="logo"
                width="160px"
                height="55px"
                onClick={() =>
                  setStyles({
                    home: "actveMenu",
                    whiteHome: "whiteActiveMenu",
                    about: "",
                    whiteAbout: "",
                    product: "",
                    whiteProduct: "",
                    contact: "",
                    whiteContact: "",
                    career: "",
                    whiteCareer: "",
                    pharma: "",
                    whitePharma: "",
                  })
                }
              />
            </div>
          </Link>

          <div className="menu">
            <div
              className={
                big
                  ? small && click
                    ? "subMenu block"
                    : "subMenu FlexNon"
                  : null
              }
            >
              <div className="navItem">
                <div className="naveItemHover">
                  <Link
                    onClick={function () {
                      HideMenuOnClick();
                      setStyles({
                        home: "actveMenu",
                        whiteHome: "whiteActiveMenu",
                        about: "",
                        whiteAbout: "",
                        product: "",
                        whiteProduct: "",
                        contact: "",
                        whiteContact: "",
                        career: "",
                        whiteCareer: "",
                        pharma: "",
                        whitePharma: "",
                      });
                    }}
                    to="/"
                    className={
                      scrollY > 100
                        ? `blue ${styles.home}`
                        : `white ${styles.whiteHome}`
                    }
                  >
                    HOME
                  </Link>
                </div>
              </div>
              <div className="navItem">
                <div className="naveItemHover">
                  <HashLink
                    onClick={function () {
                      HideMenuOnClick();
                      setStyles({
                        home: "",
                        whiteHome: "",
                        about: "actveMenu",
                        whiteAbout: "whiteActiveMenu",
                        product: "",
                        whiteProduct: "",
                        contact: "",
                        whiteContact: "",
                        career: "",
                        whiteCareer: "",
                        pharma: "",
                        whitePharma: "",
                      });
                    }}
                    to="/#aboutUs"
                    className={
                      scrollY > 100
                        ? `blue ${styles.about}`
                        : `white ${styles.whiteAbout}`
                    }
                  >
                    ABOUT US
                  </HashLink>
                </div>
              </div>
              {/* <div className="navItem">
                <div className="naveItemHover">
                  <Link
                    onClick={function () {
                      HideMenuOnClick();
                      setStyles({
                        home: "",
                        whiteHome: "",
                        about: "",
                        whiteAbout: "",
                        product: "actveMenu",
                        whiteProduct: "whiteActiveMenu",
                        contact: "",
                        whiteContact: "",
                        career: "",
                        whiteCareer: "",
                        pharma: "",
                        whitePharma: "",
                      });
                    }}
                    to="/products"
                    className={
                      scrollY > 100
                        ? `blue ${styles.product}`
                        : `white ${styles.whiteProduct}`
                    }
                  >
                    PRODUCT
                  </Link>
                </div> */}
              {/* </div> */}
              <div className="navItem">
                <div className="naveItemHover">
                  <HashLink
                    onClick={function () {
                      HideMenuOnClick();
                      setStyles({
                        home: "",
                        whiteHome: "",
                        about: "",
                        whiteAbout: "",
                        product: "",
                        whiteProduct: "",
                        contact: "actveMenu",
                        whiteContact: "whiteActiveMenu",
                        career: "",
                        whiteCareer: "",
                        pharma: "",
                        whitePharma: "",
                      });
                    }}
                    to="/#footer"
                    className={
                      scrollY > 100
                        ? `blue ${styles.contact}`
                        : `white ${styles.whiteContact}`
                    }
                  >
                    CONTACT
                  </HashLink>
                </div>
              </div>
              <div className="navItem">
                <div className="naveItemHover">
                  <HashLink
                    onClick={function () {
                      HideMenuOnClick();
                      setStyles({
                        home: "",
                        whiteHome: "",
                        about: "",
                        whiteAbout: "",
                        product: "",
                        whiteProduct: "",
                        contact: "",
                        whiteContact: "",
                        career: "actveMenu",
                        whiteCareer: "whiteActiveMenu",
                        pharma: "",
                        whitePharma: "",
                      });
                    }}
                    to="/#career"
                    className={
                      scrollY > 100
                        ? `blue ${styles.career}`
                        : `white ${styles.whiteCareer}`
                    }
                  >
                    CARRER
                  </HashLink>
                </div>
              </div>
              <div className="navItem">
                <div className="naveItemHover">
                  <Link
                    onClick={function () {
                      HideMenuOnClick();
                      setStyles({
                        home: "",
                        whiteHome: "",
                        about: "",
                        whiteAbout: "",
                        product: "",
                        whiteProduct: "",
                        contact: "",
                        whiteContact: "",
                        career: "",
                        whiteCareer: "",
                        pharma: "actveMenu",
                        whitePharma: "whiteActiveMenu",
                      });
                    }}
                    to="/pharmacovigilance"
                    className={
                      scrollY > 100
                        ? `blue ${styles.pharma}`
                        : `white ${styles.whitePharma}`
                    }
                  >
                    PHARMACOVIGILANCE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderBar;
