import React, { useState } from "react";

import emailjs from "emailjs-com";

import Pharmacovigilance from "../../assets/pharmaImage/Pharmacovigilance.jpg";

import "./pharma.css";
const Pharma = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d7s3gwp",
        "template_h1pwf59",
        e.target,
        "user_gEqCAxwhC3o2nrz63hSZ1"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setMessage("Message Sent Successfully");

    e.target.reset();
  }
  const [message, setMessage] = useState("");
  return (
    <div className="pharmaBody">
      <div className="imagePharma">
        <img src={Pharmacovigilance} alt="pharma" />
      </div>

      <div className="farmaContainer">
        <h1>PHARMACOVIGILANCE</h1>

        <h2>
          Here at the Pharmacovigilance department, we play a key role in the
          pharmaceutical industry and the development and testing of new
          medications. This will ensure that drugs on the market are safe for
          patients and identify any problems.
          <br />
          <br />
          The goal of drug vigilance (Pharmacovigilance) is to identify, analyse
          and anticipate adverse reactions resulting from the use of a drug. It
          is of utmost importance to ensure that a drug remains safely used, and
          to update its prescription when necessary. Vigilance is based on
          professional judgments by specialists who flag putative side effects
          to the network.
          <br />
          <br />
          For proper assessments it is crucial for the consumers to state
          personal feedback from their drug experience. The aim is to enhance
          patient care and patient safety in relation to using the medicine. It
          is also a source of providing reliable, balanced information for the
          effective assessment of risk-benefit profile of medicines. This may
          result in the withdrawl or restriction of drugs due to safety concerns
          that only emerged post-commercialization.
          <br />
          <br />
          For this reason we would like you to admit a report of any nature to
          achieve the preferable outcome the of analysis. To submit a report
          please fill out the following form:
        </h2>

        <form className="formWhite" onSubmit={sendEmail}>
          <p className="pharmaText">
            Identities of reporter will remain confidantial
          </p>
          <input
            className="inputPharma"
            type="text"
            placeholder="Full Name"
            name="FullName"
            autoComplete="off"
            required
          />
          <br />
          <input
            className="inputPharma"
            type="text"
            placeholder="Age"
            name="Age"
            autoComplete="off"
            required
          />
          <br />
          <select
            name="Gender"
            className="inputPharma"
            defaultValue=""
            placeholder="gender"
            style={{ color: "gray" }}
            required
          >
            <option value="" disabled>
              Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <br />
          <input
            className="inputPharma"
            type="text"
            placeholder="Phone Number"
            name="Phone"
            autoComplete="off"
            required
          />
          <br />
          <input
            className="inputPharma"
            type="email"
            placeholder="Email"
            name="Email"
            autoComplete="off"
            required
          />
          <br />
          <input
            className="inputPharma"
            type="text"
            placeholder="Address"
            name="Adress"
            autoComplete="off"
            required
          />
          <br />
          <input
            className="inputPharma"
            type="text"
            placeholder="Pregnancy Yes Or No Or If Yes Which Month ?"
            name="MaritalStatus"
            autoComplete="off"
            required
          />
          <br />
          <textarea
            className="inputAera"
            placeholder="Medical History (Diseases That You Suffer From) "
            name="MidicalHistory"
            autoComplete="off"
            required
          ></textarea>
          <br />
          <textarea
            className="inputAera"
            placeholder="Current Medications (Chronic) "
            name="CurrentMidication"
            autoComplete="off"
            required
          ></textarea>
          <br />
          <input
            className="inputPharma"
            type="text"
            placeholder="What Medication Did You Consume And Caused You Adverse Effect(s)"
            name="WhatMidication"
            autoComplete="off"
            required
          />
          <br />
          <br />
          <textarea
            className="inputAera"
            name="DescribeDeteails"
            placeholder="Describe The Adverse Eeffect That Happened To You In Details"
            autoComplete="off"
            required
          ></textarea>
          <br />
          <br />
          <textarea
            className="inputAera"
            placeholder="From Where Did You Get That Medication And Who Prescribed It To You (Name ,Career ,Phone Number And Work Place Of Prescriber . Site And Phone Number Of Prescription Site )"
            autoComplete="off"
            name="FromWhere"
            required
          ></textarea>
          <input
            className="inputPharma"
            type="text"
            placeholder="How Did You Store It"
            name="StoreIt"
            autoComplete="off"
            required
          />
          <br />
          <input
            className="inputPharma"
            type="text"
            placeholder="How Did You Use The Medication ( Dosage ,Frequency And Way Of Usage )"
            name="HowUseIt"
            autoComplete="off"
            required
          />
          <br />
          <br />
          {message ? (
            <div className="alert alert-success">{message}</div>
          ) : null}
          <br />
          <br />
          <h2>
            thank you for your time ... we will contact you soon ,
            <br />
            your health is our priority
          </h2>
          <br />
          <button className="btn btanCarer "></button>
        </form>
      </div>
    </div>
  );
};

export default Pharma;
