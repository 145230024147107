import React from "react";
import Pharma from "../../components/Pharma/Pharma";

const PharmaPage = () => {
  return (
    <div>
      <Pharma />
    </div>
  );
};

export default PharmaPage;
