import React from "react";

import LogIn from "../../components/LogIn/LogIn";

import controlPanel from "../../assets/panelImage/controlPanel.png";
import { AuthProvider } from "../../components/useContext/AuthContext";

const SignIn = () => {
  return (
    <AuthProvider>
      <img src={controlPanel} alt="" width="100%" />
      <LogIn />
    </AuthProvider>
  );
};

export default SignIn;
