import React from "react";

// firebase dataBsae
import { db } from "../fireBase/config";

// react icons
import { MdDeleteForever } from "react-icons/md";

const RemoveItem = ({ id, fileImage, title, setSuccess }) => {
  // remove a product from firebase dataBase
  const removeProducts = (id) => {
    console.log(id);
    db.collection("products").doc(id).delete();
    setSuccess("Deleted A Product Successfully");
  };

  return (
    <div>
      <div className="card mt-5 mx-2" style={{ width: "18rem" }}>
        <img
          src={fileImage}
          alt="fileimage"
          className="card-img-top"
          style={{ height: "150px" }}
        />
        <div className="card-body d-flex justify-content-between">
          <h5 className="card-title">{title}</h5>

          <button
            onClick={() => removeProducts(id)}
            className="bg-white border-0"
          >
            <MdDeleteForever
              data-toggle="tooltip"
              title="delete a product"
              size="2rem"
              color="#f00"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveItem;
