import React, { useState } from "react";

// custom hooks
import UploadImage from "../customHooks/uploadImage";

// components
import RemoveItem from "../removeItem/RemoveItem";

const RemoveProduct = () => {
  // custom hooks
  const { data } = UploadImage();
  // useState hooks

  const [success, setSuccess] = useState("");

  return (
    <div className="">
      <h1 className="text-center pb-5"> Remove A Product</h1>
      <hr />

      {success ? (
        <div className="alert alert-success text-center">{success}</div>
      ) : null}

      {/* remove item components */}

      <div className="imageRemoveContaner  d-flex flex-row flex-wrap justify-content-start">
        {data.map(({ fileImage, title, id }) => (
          <RemoveItem
            key={id}
            id={id}
            fileImage={fileImage}
            title={title}
            setSuccess={setSuccess}
          />
        ))}
      </div>
    </div>
  );
};

export default RemoveProduct;
