import React, { useRef, useState } from "react";

import { useAuth } from "../useContext/AuthContext";
import { useHistory } from "react-router-dom";

import "./logIn.css";
import LogInContext from "../useContext/LogInContext";

// custom hooks

// import { Authintication } from "../customHooks/authintication";

const LogIn = () => {
  // custom hook
  // const { setIsLogIn } = Authintication();
  // useState variables

  const userName = useRef();
  const password = useRef();
  const [error, setError] = useState("");
  const { logIn } = useAuth();
  const history = useHistory();

  async function submitHandler(e) {
    e.preventDefault();

    try {
      setError("");
      await logIn(userName.current.value, password.current.value);
      // setIsLogIn(true);
      history.push("/AuthDashBoard");
    } catch {
      setError("wrong email or password");
    }
  }
  const logInHandler = (changeAuth) => {
    error ? changeAuth() : changeAuth(1);
  };

  return (
    <div className="LogIn">
      <LogInContext.Consumer>
        {({ changeAuth }) => (
          <>
            <form className="form" onSubmit={submitHandler}>
              <h1 className="headForm">Log In</h1>
              {error ? (
                <div className="alert dangaresalert alert-danger">{error}</div>
              ) : null}

              <input
                type="text"
                placeholder="user name"
                ref={userName}
                className="form-control"
              />
              <br />
              <input
                type="password"
                placeholder="password"
                className="form-control"
                ref={password}
              />
              <br />
              <br />
              <button
                type="submit"
                className="btn btn-primary w-100"
                onClick={() => logInHandler(changeAuth)}
              >
                submit
              </button>
              <br />
              <br />
            </form>
          </>
        )}
      </LogInContext.Consumer>
    </div>
  );
};

export default LogIn;
