import React from "react";

import Are_our_core from "../../assets/SliderImage/Are_our_core.jpg";
import Care from "../../assets/SliderImage/Care.jpg";
import innovation from "../../assets/SliderImage/innovation.jpg";
import Value from "../../assets/SliderImage/Value.jpg";

import "./landslider.css";

function LandSlider() {
  return (
    <div className="sliderImage">
      <div
        id="carouselExampleIndicators"
        className="carousel slide carousel-fade mainContainerSlider"
        data-ride="carousel"
        data-pause="false"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
            data-interval="2000"
          ></li>
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="1"
            data-interval="2000"
          ></li>
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="2"
            data-interval="2000"
          ></li>
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="3"
            data-interval="2000"
          ></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={Value} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h1 style={{ fontSize: "90px" }}>VALUE ..</h1>
            </div>
          </div>

          <div className="carousel-item ">
            <img src={Care} className="d-block w-100 " alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h1 style={{ fontSize: "90px" }}>CARE ..</h1>
            </div>
          </div>

          <div className="carousel-item">
            <img src={innovation} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h1 style={{ fontSize: "90px" }}>INNOVATION ..</h1>
            </div>
          </div>

          <div className="carousel-item">
            <img src={Are_our_core} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h1 style={{ fontSize: "90px" }}>ARE OUR CORE ..</h1>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="whiteSpace"></div>
    </div>
  );
}

export default LandSlider;
