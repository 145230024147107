import React, { useContext } from "react";
import { auth } from "../fireBase/config";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  function logIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  const value = {
    logIn,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
