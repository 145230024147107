import React from "react";

import "./homepage.css";
// import components

import LandSlider from "../../components/landing/landSlider";
import Partnars from "../../components/partnars/Partnars";
// import AboutUs from "../../components/AboutUs/AboutUs";
import AboutUs from "../../components/aboutUs/AboutUs";
import Carer from "../../components/Carer/Carer";

function HomePage(props) {
  return (
    <>
      <LandSlider />
      <Partnars />
      <AboutUs />
      <Carer />
      {/* <HeddingFooter /> */}
    </>
  );
}

export default HomePage;
