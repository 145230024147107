import React from "react";
import "./partnars.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import partnars logos

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import kindal from "../../assets/partnarsLogos/kindal.png";
import dayonix from "../../assets/partnarsLogos/dayonix.png";
import biohealth from "../../assets/partnarsLogos/biohealth.png";
import novax from "../../assets/partnarsLogos/novax.png";
import vuab from "../../assets/partnarsLogos/vuab.png";
import abiogen from "../../assets/partnarsLogos/abiogen.png";
import exrtactumPharma from "../../assets/partnarsLogos/exrtactumPharma.png";
import lrubio from "../../assets/partnarsLogos/lrubio.png";
// import meditop from "../../assets/partnarsLogos/meditop.png";

const items = [
  <div className="partnarImage">
    <img className="imagePartnarLogoWidth" src={abiogen} alt="abiogen logo" />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- Abiogen Pharma , Italy </h4>
        <br />
        <h6>
          an Italian excellence in the bone pathologies therapy sector and an
          important reference point on the national scientific scene
        </h6>
        <button className="btn buttonOnLogoShadow">
          <a href="https://www.abiogen.it/en/" target="blank">
            Vist Site
          </a>
        </button>
      </div>
    </div>
  </div>,
  <div className="partnarImage">
    <img className="imagePartnarLogoWidth" src={lrubio} alt="lrubio logo" />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- Laboratorios Rubio SA </h4>
        <br />
        <h6>Pioneers in introducing important and life-changing drugs.</h6>
        <button className="btn buttonOnLogoShadow">
          <a href="https://www.laboratoriosrubio.com/en" target="blank">
            Vist Site
          </a>
        </button>
      </div>
    </div>
    <div className="partnarSpace"></div>
  </div>,

  <div className="partnarImage">
    <img className="imagePartnarLogoWidth" src={dayonix} alt="dayonix logo" />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- Dayonix pharma , UK </h4>
        <br />
        <h6>
          specializing in innovation, development, design and strategic
          marketing of various food supplements for all ages.
        </h6>
        <button className="btn buttonOnLogoShadow">
          <a href="https://dayonixpharma.com/" target="blank">
            Vist Site
          </a>
        </button>
      </div>
    </div>
  </div>,

  <div className="partnarImage">
    <img
      className="imagePartnarLogoWidth"
      src={exrtactumPharma}
      alt="exrtactumPharma logo"
    />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- ExtractumPharma </h4>
        <br />
        <h6>
          The main purpose is to contribute to the improvement of the health
          culture, the quality of life of people.
        </h6>
        <button className="btn buttonOnLogoShadow">
          <a href="https://www.expharma.hu/?q=en" target="blank">
            Vist Site
          </a>
        </button>
      </div>
    </div>
  </div>,

  <div className="partnarImage">
    <img className="imagePartnarLogoWidth" src={kindal} alt="kindal logo" />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- Kendal Nutricare, UK </h4>
        <br />
        <h6>
          58 Years Of Nutrition Experience. the world’s best infant bottle feed
          alternative to the gold standard of breastfeeding.
        </h6>
        <button className="btn buttonOnLogoShadow">
          <a href="https://kendalnutricare.com/" target="blank">
            Vist Site
          </a>
        </button>
      </div>
    </div>
    <div className="partnarSpace"></div>
  </div>,

  <div className="partnarImage">
    <img
      className="imagePartnarLogoWidth"
      src={biohealth}
      alt="biohealth logo"
    />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- Biohealth, Canada </h4>
        <br />
        <h6>
          a pioneer in advanced healthcare products and provide consumers with
          innovative proprietary formulations.
        </h6>
        <button className="btn buttonOnLogoShadow">
          <a
            href="http://biohealthlabs.ca/"
            target="blank"
            onClick={() => console.log("work")}
          >
            Vist Site
          </a>
        </button>
      </div>
    </div>
  </div>,

  <div className="partnarImage">
    <img className="imagePartnarLogoWidth" src={novax} alt="novax logo" />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- Novax Pharma, France </h4>
        <br />
        <h6>
          an innovative ophthalmic medical device company, specialized in the
          formulation,manufacturing, marketing and distribution of eye care
          solutions.
        </h6>
        <button className="btn buttonOnLogoShadow">
          <a href="http://www.novaxpharma.com/" target="blank">
            Vist Site
          </a>
        </button>
      </div>
    </div>
  </div>,

  <div className="partnarImage">
    <img className="imagePartnarLogoWidth" src={vuab} alt="vuab logo" />
    <div className="shadowLogo">
      <div className="textShadowAlign">
        <h4 className="shadoTextHead">- Vuab Pharma, Czech </h4>
        <br />
        <h6>
          a traditional Czech manufacturer in the field of pharmacy. It is part
          of a major industrial group SAFICHEM GROUP.
        </h6>
        <button className="btn buttonOnLogoShadow">
          <a href="https://www.vuab.cz/" target="blank">
            Vist Site
          </a>
        </button>
      </div>
    </div>
  </div>,
];
const Partnars = () => {
  return (
    <div className="partnarContant">
      <h1>OUR PARTNARS</h1>
      <AliceCarousel autoWidth infinite mouseTracking items={items} />
    </div>
  );
};

export default Partnars;
