import React from "react";

import "./footer.css";

import logoWhite from "../../assets/footerLogo/logoWhite.png";
import { SiLinkedin } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="subFooter" id="footer">
        {/* footer lication */}
        <div className="FooterLocation">
          {/* map from google map  */}
          <h2>Our Location</h2>
          <iframe
            title="googl map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d210.18872310855429!2d35.85691058489166!3d32.55232295766212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c776666f0b789%3A0x22022cd9e1b591b9!2sAl-Omawi%20Drug%20Store!5e0!3m2!1sen!2sjo!4v1613498645444!5m2!1sen!2sjo"
            width="300"
            height="200"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
          <h4>Hashemite Kingdom of Jordan</h4>
        </div>

        {/* footer contact us  */}
        <div className="FootercontactUs">
          <h1>Contact Us</h1>
          <br />
          <h5>Tel : +962 2 7248011</h5>
          <br />
          <h5>Mobile : +962-799-708-282</h5>
          <br />
          <h5>Email : info@alomawicorp.com</h5>
        </div>

        {/* footer follow us  */}
        <div className="FooterFollowUs">
          <h2>FOLLOW US :</h2>
        
          <br />

          <a href="https://www.facebook.com/AL-Omawi-Drug-Store-104159365068856/">
            <FaFacebook className="socialMedia" color="#fff" />
          </a>
          <br />
          <a href="https://www.linkedin.com/in/alomawi-drug-store-033bb9117/">
            <SiLinkedin className="socialMedia" color="#fff" />
          </a>
        </div>

        <div className="footerLogo">
          <img src={logoWhite} alt="logo" width="250px" />
        </div>
      </div>
      <div className="copyRight">
        <span>alomawidrugstore@2021</span>
      </div>
    </div>
  );
};

export default Footer;
