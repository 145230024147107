import React, { useState } from "react";

// for sending emails
import emailjs from "emailjs-com";
import "./carer.css";

const Carer = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d7s3gwp",
        "template_vgd9ruu",
        e.target,
        "user_gEqCAxwhC3o2nrz63hSZ1"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setMessage("Message Sent Successfully");

    e.target.reset();
  }

  const [message, setMessage] = useState("");
  return (
    <div className="careerCareer" id="career">
      <h1>CAREER</h1>

      <form className="formWhite" onSubmit={sendEmail}>
        <p className="careerText">
          If you want to be one of our team, you can send your info here
        </p>
        <input
          className="inputCarer"
          type="text"
          placeholder="Full Name"
          name="FullName"
          autoComplete="off"
          required
        />
        <br />
        <input
          required
          className="inputCarer"
          type="email"
          placeholder="Email"
          name="Email"
          autoComplete="off"
        />
        <br />
        <input
          className="inputCarer"
          type="text"
          placeholder="Skills"
          required
          name="Skills"
          autoComplete="off"
        />
        <br />
        <br />
        <br />
        <textarea
          required
          className="inputAera"
          placeholder="About Yourself"
          name="AboutYourSelf"
          autoComplete="off"
        ></textarea>
        <br />
        <br />

        {/* alert if the form is not complete */}
        {message ? <div className="alert alert-success">{message}</div> : null}
        <br />
        <br />
        <button className="btn btanCarer "></button>
      </form>
    </div>
  );
};

export default Carer;
