import React from "react";
import RemoveProduct from "../../components/removeProducts/RemoveProduct";
import TextEara from "../../components/TextEara/TextEara";
import controlPanel from "../../assets/panelImage/controlPanel.png";
//  context api

import LogInContext from "../../components/useContext/LogInContext";

import "./auth.css";
const Auth = () => {
  return (
    <div className="dashBoardContainer">
      <img src={controlPanel} alt="" />
      <LogInContext.Consumer>
        {({ logIn }) => (
          <>
            {logIn ? (
              <>
                <TextEara />
                <RemoveProduct />
              </>
            ) : null}
            {console.log(logIn)}
          </>
        )}
      </LogInContext.Consumer>
    </div>
  );
};

export default Auth;
