import React, { useRef, useState } from "react";

import { db } from "../fireBase/config";
import { dbStorage } from "../fireBase/config";
import "./textEara.css";

const TextEara = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState(null);
  const noneHealthCare = useRef();
  const title = useRef();
  const healthCare = useRef();

  const UploadImage = (e) => {
    // Create a root reference

    let selected = e.target.files[0];
    if (selected) {
      setImage(selected.name);
      // refrences
      const storageRef = dbStorage.ref(selected.name);
      storageRef.put(selected).on(
        "state_change",

        async () => {
          const url = await storageRef.getDownloadURL();
          setUrl(url);
        }
      );

      //   setImage(selected.name);
    }
  };

  const handleText = () => {
    // add product to the data base
    if (noneHealthCare.current.value && image && title.current.value) {
      db.collection("products").add({
        noneHealthCare: noneHealthCare.current.value,
        title: title.current.value,
        fileImage: url,
        healthCare: healthCare.current.value,
      });

      // impty form input

      noneHealthCare.current.value = "";
      title.current.value = "";
      healthCare.current.value = "";
      setImage("");
      setError("");
      // success added
      setSuccess("Inserted A Product Successfuly");
    } else {
      // failed added
      image
        ? setError("select type of image is png")
        : setError("Input All Product Information ");
    }
  };
  return (
    <div className="textEaraContainer">
      <h1>Insert A Product</h1>
      {/* alert for success or failed inserted products */}

      {error ? (
        <div className="alert alert-danger">{error}</div>
      ) : success ? (
        <div className="alert alert-success">{success}</div>
      ) : null}

      {/* form input products  */}

      <input
        type="text"
        placeholder="title"
        className="form-control"
        ref={title}
      />
      <br />
      <input type="file" className="form-control" onChange={UploadImage} />
      <br />
      <textarea
        className="form-control"
        name="inputProduct"
        id="input"
        cols="80"
        rows="10"
        placeholder=" None Health Care Definition..."
        ref={noneHealthCare}
      ></textarea>
      <br />

      <textarea
        className="form-control"
        name="inputProduct"
        id="input"
        cols="80"
        rows="10"
        placeholder=" Health Care Definition..."
        ref={healthCare}
      ></textarea>
      <br />

      <button className="btn btn-primary" onClick={handleText}>
        submit
      </button>
      <br />
      <hr />

      {/* here somr code  */}
    </div>
  );
};

export default TextEara;
