import { useState, useEffect } from "react";

import { db } from "../fireBase/config";

export default function UploadImage() {
  async function fectData() {
    await db
      .collection("products")
      .get()
      .then((res) => {
        res.forEach((doc) => {
          setData((data) => [
            ...data,
            {
              id: doc.id,
              title: doc.data().title,
              noneHealthCare: doc.data().noneHealthCare,
              fileImage: doc.data().fileImage,
              healthCare: doc.data().healthCare,
            },
          ]);
        });
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });

    //   setLoading(true);
    //   setData([{ title: "sd", textEara: "sd" }]);
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fectData();
  }, []);

  return {
    data,
    loading,
  };
}
