import React, { useState } from "react";
// styles
import "./App.css";

// import pages
import HomePage from "./pages/HomePage/HomePage";
import SignIn from "./pages/SignIn/SignIn";
import Auth from "./pages/authainticationUploadProducts/auth";
import ProductsPage from "./pages/Products/ProductsPage";
import ProductDetails from "./pages/productDetails/ProductDetails";
import PharmaPage from "./pages/pharma/PharmaPage";
import HealthCare from "./pages/healthCare/HealthCare";
import NoneHealthCarePage from "./pages/noneHealthCare/NoneHealthCarePage";

// import components
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/scrollUpOfPage/ScrollToTop";

// context api
import LogInContext from "./components/useContext/LogInContext";
import HealthDetailContext from "./components/useContext/HealthDetailContext";

// import react modules

import { Switch, Route } from "react-router-dom";
import HeaderBar from "./components/headerBar/HeaderBar";

function App() {
  //*******  for context api *********
  //
  const [logIn, setLogIn] = useState(false);
  //

  const [healthDetail, setHealthDetail] = useState({});

  // for set handler ptoduct
  const setHealthHandler = (obj) => {
    setHealthDetail(obj);
  };

  const changeAuth = () => {
    // he is successfully loged in
    setLogIn(true);
  };
  return (
    <>
      <ScrollToTop />
      <HeaderBar />
      <Switch className="heddingFooter">
        <Route exact path="/" component={() => <HomePage />} />

        {/* <Route exact path="/products" component={() => <ProductsPage />} />

        <HealthDetailContext.Provider
          value={{
            healthDetail: healthDetail,
            setHealthHandler: setHealthHandler,
          }}
        >
          <Route exact path="/products/healthCare" component={() => <HealthCare />} />
          <Route
            exact
            path="/products/noneHealthCare"
            component={() => <NoneHealthCarePage />}
          /> */}
        {/* <Route
            exact
            path="/products/healthCare/productDetails"
            component={ () => <ProductDetails />}
          /> */}
        {/* </HealthDetailContext.Provider> */}
      </Switch>

      {/* for log in and control panel */}
      <Switch>
        <LogInContext.Provider value={{ logIn: logIn, changeAuth: changeAuth }}>
          <Route exact path="/signInAsAuthAdmin" component={() => <SignIn />} />
          <Route exact path="/AuthDashBoard" component={() => <Auth />} />
        </LogInContext.Provider>
      </Switch>
      <Switch>
        <Route
          exact
          path="/pharmacovigilance"
          component={() => <PharmaPage />}
        />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
